import { Progress } from "antd";
import { UPLOAD_STATUSES } from "../../../utils/constants/common";
import { FileIcon, GreenCheck, CloseBtn } from "../../Icons/UploadIcons";
import { FlexBox, UploadStatusWrapper } from "../UploadDocumentStyled";

export const UploadStatusCard = (props: {
  status: string;
  progressPercentage: { percentage: number; progress: string };
  reset: Function;
  filename: string;
}) => {
  const {
    status,
    progressPercentage: { percentage, progress },
    reset,
    filename,
  } = props;
  return (
    <UploadStatusWrapper color={status}>
      <FileIcon />
      <div className="filename-container">
        <div className="filename" title={filename}>
          {filename}
        </div>
        {status === UPLOAD_STATUSES.inProgress && (
          <div className="progress">
            <div className="progress-indicator">
              <Progress
                percent={percentage}
                showInfo={false}
                strokeColor="#ffbf00"
              />
            </div>
            <div className="progress-text">{progress}</div>
          </div>
        )}
        {status === UPLOAD_STATUSES.success && (
          <FlexBox>
            <GreenCheck />
            <span className="text-green">FILE UPLOADED SUCCESSFULLY</span>
          </FlexBox>
        )}
        {status === UPLOAD_STATUSES.error && (
          <FlexBox>
            <span className="text-red">
              FILE COULD NOT BE UPLOADED, PLEASE RETRY
            </span>
          </FlexBox>
        )}
      </div>
      <div
        onClick={() => reset()}
        className={
          status === UPLOAD_STATUSES.inProgress ? "btn-disabled" : "close-btn"
        }
      >
        <CloseBtn />
      </div>
    </UploadStatusWrapper>
  );
};
