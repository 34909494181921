import { useState } from "react";
import { Button, UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useLocation } from "react-router-dom";
import {
  UploadModalTitleWrapper,
  UploadModalWrapper,
} from "./UploadDocumentStyled";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setOpenUploadModal } from "../../store/features/UploadDocument.slice";
import { FileIcon } from "../Icons/UploadIcons";
import { ApiUrl } from "../../utils/constants/ApiUrl";
import { GET, POST_FORMDATA } from "../../service/api";
import { byteConverter, triggerToastMessage } from "../../utils/utils";
import {
  FILE_UPLOAD,
  STATUS,
  UPLOAD_STATUSES,
} from "../../utils/constants/common";
import { UploadStatusCard } from "./UploadStatusCard/UploadStatusCard";
import SelectCategory, {
  ICategoryOptions,
} from "./SelectCategory/SelectCategory";

const UploadDocument = (prop: {
  activeNode: string;
  options?: Array<ICategoryOptions>;
  onUpload?: Function;
}) => {
  const dispatch = useAppDispatch();
  const [filename, setFilename] = useState("");
  const [progressPercentage, setProgressPercentage] = useState({
    percentage: 0,
    progress: "",
  });
  const [uploadStatus, setUploadStatus] = useState(UPLOAD_STATUSES.notStarted);
  const { openUploadModal } = useAppSelector((state) => state.UploadReducer);
  const reset = () => {
    setUploadStatus(UPLOAD_STATUSES.notStarted);
    dispatch(setOpenUploadModal(false));
    setBtnStatus(true);
  };
  const location = useLocation();
  const [btnStatus, setBtnStatus] = useState(true);
  const [category, setCategory] = useState<ICategoryOptions | null>(null);

  const upload = (response: any) => {
    const uploadFile = response.file;
    let isValid = validateFileUpload(uploadFile);
    if (isValid) {
      let { id, payload } = processUpload(uploadFile);
      dispatch(
        POST_FORMDATA("document/upload", ApiUrl.uploadDocument, payload)()
      ).then((res: any) => {
        clearInterval(id);
        if (res?.payload?.status === STATUS.SUCCESS) {
          // for reloading the documents
          _refreshDocs(prop?.activeNode);
          let percentageProg = setProgress(100, uploadFile.size);
          setProgressPercentage(percentageProg);
          setUploadStatus(UPLOAD_STATUSES.success);
        } else {
          setUploadStatus(UPLOAD_STATUSES.error);
        }
      });
    }
  };

  const processUpload = (file: any) => {
    let duration = 5;
    setUploadStatus(UPLOAD_STATUSES.inProgress);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file?.name ?? "");
    setFilename(file.name);
    const payload = {
      file: file,
      node: category?.value || prop.activeNode,
    };
    const id = setInterval(() => (duration = duration + 20), 100);
    let percentageProg = setProgress(duration, file.size);
    setProgressPercentage(percentageProg);
    return { id, payload };
  };

  const getExtension = (fileName: string) => {
    let type = fileName?.split(".")?.at(-1);
    const ext =
      type == "gz" ? `.${fileName?.split(".")?.at(-2)}.${type}` : `.${type}`;
    return ext?.toLowerCase();
  };

  const validateFileUpload = (file: any) => {
    const fileSize = parseFloat(
      byteConverter(file.size, 1, "MB")?.replace(" MB", "")
    );
    const fileExtension = getExtension(file?.name);
    if (!FILE_UPLOAD.FILE_TYPES.includes(fileExtension)) {
      triggerToastMessage(
        `This file type is not supported.`,
        STATUS.ERROR,
        ` Supported formats: ${FILE_UPLOAD.FILE_TYPES.join(", ")}.`
      );
      return false;
    }
    if (fileSize > FILE_UPLOAD.MAX_SIZE_MB) {
      triggerToastMessage(
        `Please upload a file with a maximum size of ${FILE_UPLOAD.MAX_SIZE_MB} MB`,
        STATUS.ERROR
      );
      return false;
    }
    return true;
  };

  const setProgress = (percentage: number, total: number) => {
    let uploadedSize = (percentage * total) / 100;
    let percentageProg = {
      percentage: percentage,
      progress: `${byteConverter(uploadedSize, 1)}/${byteConverter(total, 1)}`,
    };
    return percentageProg;
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    accept: FILE_UPLOAD.FILE_TYPES.toString(),
    className: "",
    customRequest: upload,
    disabled: prop.options ? btnStatus : false,
  };

  // const setNodeChange = (value: string) => {
  //   setSelectedNode(value);
  //   setUploadStatus(UPLOAD_STATUSES.notStarted);
  // };

  const _refreshDocs = (selectedNode: string) => {
    if (prop.onUpload) {
      prop.onUpload();
      return;
    }
    location?.pathname?.includes("customers")
      ? dispatch(
          GET(
            "customerSpecific/files",
            `${ApiUrl.customerSpecificNodes}${selectedNode}/documents/`
          )()
        )
      : dispatch(
          GET(
            "nodes/files",
            `${ApiUrl.productList}${selectedNode}/documents/`
          )()
        );
  };

  return (
    <UploadModalWrapper
      closable={true}
      width={964}
      destroyOnClose={true}
      className="modal-title"
      title={
        <UploadModalTitleWrapper>
          Upload File
          {/* <SelectFolderButton
            activeNode={prop.activeNode}
            onChange={(ev: any) => setNodeChange(ev)}
            nodes={prop.nodes}
          /> */}
        </UploadModalTitleWrapper>
      }
      open={openUploadModal}
      onOk={reset}
      onCancel={reset}
      footer=""
    >
      {prop.options && (
        <SelectCategory
          options={prop.options}
          setCategory={setCategory}
          setBtnStatus={setBtnStatus}
        />
      )}
      <div className="upload-container">
        {uploadStatus === UPLOAD_STATUSES.notStarted && (
          <Dragger {...props}>
            <div className="drag-area-content">
              <FileIcon />
              <div className="main-text">Upload or drag and drop a file</div>
              <div className="sub-text">
                {`Supported formats -
                ${FILE_UPLOAD.FILE_TYPES.map((ext: string) =>
                  ext.replace(".", "")
                ).join(", ")}.`}
              </div>
              <Button
                className="choose-file"
                disabled={prop.options ? btnStatus : false}
              >
                Choose File
              </Button>
            </div>
          </Dragger>
        )}
        {uploadStatus !== UPLOAD_STATUSES.notStarted && (
          <UploadStatusCard
            filename={filename}
            status={uploadStatus}
            progressPercentage={progressPercentage}
            reset={() => setUploadStatus(UPLOAD_STATUSES.notStarted)}
          />
        )}
      </div>
    </UploadModalWrapper>
  );
};

export default UploadDocument;
