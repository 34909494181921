import { useEffect, useState } from "react";
import styled from "styled-components";

const CountBadge = styled.span`
  display: inline-block;
  width: 2.9rem;
  height: 2.9rem;
  border-radius: 4rem;
  padding: 0.4rem 0.65rem;
  background: var(--COUNT-BADGE);
  color: var(--TABLE-HEADER);
  margin-left: 1rem;
  font-size: 1.5rem;
`;

const ProductName = ({ products }: { products: string[] }) => {
  const [badgeCount, setBadgeCount] = useState<number>(0);

  useEffect(() => {
    const length = products.length;
    if (length > 2) {
      setBadgeCount(length - 2);
      return;
    }
    setBadgeCount(0);
  }, [products?.length]);

  return (
    <div>
      <span>{products.slice(0, 2).join(", ")}</span>
      {badgeCount > 0 && <CountBadge> {badgeCount}+</CountBadge>}
    </div>
  );
};

export default ProductName;
