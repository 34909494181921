import { useEffect } from "react";
import { MenuProps } from "antd";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { INodes } from "../../../interface/ProductDetails";
import { DELETE, GET } from "../../../service/api";
import {
  fetchNodeDetails,
  setActiveNode,
  setBreadCrumbNodes,
  setConfirmFolderDelete,
  setCustomerActiveNode,
  setNodes,
  setOpenDeleteFolderModalSt,
  updateDocPage,
} from "../../../store/features/FolderTree";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import { STATUS } from "../../../utils/constants/common";
import {
  capitalizeFirstLetter,
  isSuperAdminUser,
  triggerToastMessage,
} from "../../../utils/utils";
import { RightArrow, FolderIcon } from "../../Icons/Icons";
import { TreeLoader } from "../../Loader/Loader";
import ActionMenu from "../../ActionMenu/ActionMenu";
import { updateCustomerDocPage } from "../../../store/features/CustomerDetails.slice";

const items: MenuProps["items"] = [
  {
    key: "1",
    label: "Delete folder",
    danger: true,
  },
];

export const TreeNode = ({
  data,
  id,
  name,
  isExpanded,
  isLoading = false,
  children = [],
  type = "",
}: {
  data: INodes;
  id: string;
  name: string;
  isExpanded: boolean;
  isLoading?: boolean;
  children?: INodes[];
  type?: string;
}) => {
  const dispatch = useAppDispatch();
  const {
    active_node,
    nodes,
    fetchNode,
    customer_active_node,
    confirmFolderDelete,
  } = useAppSelector((state) => state.FolderTreeReducer);
  const { selectedRecord } = useAppSelector(
    (state) => state.CustomerDetailsReducer
  );
  const isSuperAdmin = isSuperAdminUser();

  useEffect(() => {
    //to auto expand and show newly added folders in tree
    if (fetchNode && !type) {
      expandOrCollapseNode(active_node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNode, active_node, type]);

  useEffect(() => {
    confirmFolderDelete && data?.id === selectedRecord?.id && _deleteFolder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmFolderDelete, selectedRecord?.id]);

  const expandOrCollapseNode = (treeNode: INodes) => {
    !type && _createSelectedNodeParams(treeNode);
  };

  const selectNode = (selectedNode: INodes) => {
    if (
      active_node?.id === selectedNode?.id ||
      customer_active_node?.id === selectedNode?.id
    ) {
      return;
    }
    !type
      ? dispatch(setActiveNode(selectedNode))
      : dispatch(setCustomerActiveNode(selectedNode));
    if (!selectedNode?.path && !type) _fetchChildNodes(selectedNode, true);
    _fetchDocs(type, selectedNode);
    _createBreadcrumbOptions(selectedNode);
  };

  const _fetchDocs = (type: string, selectedNode: INodes) => {
    // fetching pragma node documents
    if (!type) {
      dispatch(
        GET(
          "nodes/files",
          `${ApiUrl.productList}${selectedNode?.id}/documents/?page_size=10&page=1`
        )() // fetching the call again on page change
      );
      dispatch(updateDocPage(1));
      return;
    }
    dispatch(
      GET(
        "customerSpecific/files",
        `${ApiUrl.customerSpecificNodes}${selectedNode?.id}/documents/?page_size=10&page=1`
      )()
    );
    dispatch(updateCustomerDocPage(1));
  };

  const _createSelectedNodeParams = (treeNode: INodes) => {
    treeNode = {
      ...treeNode,
      expanded:
        (treeNode?.sub_nodes || [])?.length > 0
          ? !treeNode.expanded
          : treeNode?.expanded,
    };
    // if clikced on the root node --> set the nodes as the selected node

    if (treeNode?.is_root_node) {
      !fetchNode ? dispatch(setNodes(treeNode)) : _fetchChildNodes(treeNode);
      return;
    }
    //If the node is clicked for a first time, fetch the results from API
    if (!Object.hasOwn(treeNode, "sub_nodes") || fetchNode) {
      _fetchChildNodes(treeNode);
      return;
    }
    //If node is clicked before and has sub nodes, update the expand icon status
    const updatedTree = _updateTreeData(
      nodes?.sub_nodes || [],
      treeNode,
      treeNode?.sub_nodes || []
    );
    dispatch(setNodes({ ...nodes, sub_nodes: [...updatedTree] }));
  };

  const _fetchChildNodes = (treeNode: INodes, nodeSelected = false) => {
    treeNode = { ...treeNode, loading: !nodeSelected };
    dispatch(
      GET("node/details", `${ApiUrl.productList}${treeNode.id}/`)()
    ).then((res) => {
      fetchNode && dispatch(fetchNodeDetails(false));
      treeNode = { ...treeNode, loading: false };
      const {
        payload: { data, status },
      } = res;
      if (status === STATUS.SUCCESS) {
        treeNode = {
          ...treeNode,
          path: data?.path,
          sub_nodes: [...data?.sub_nodes],
          expanded: !nodeSelected && data?.sub_nodes?.length > 0,
        };
        if (treeNode?.is_root_node) {
          dispatch(
            setNodes({
              ...nodes,
              sub_nodes: [...data?.sub_nodes],
            })
          );
          return;
        }
        const updatedTree = _updateTreeData(
          nodes?.sub_nodes || [],
          treeNode,
          data?.sub_nodes
        );
        dispatch(setNodes({ ...nodes, sub_nodes: [...updatedTree] }));
        if (nodeSelected) _createBreadcrumbOptions(treeNode);
      }
    });
  };

  const _updateTreeData = (
    productNodes: INodes[],
    treeNode: INodes,
    childData: INodes[]
  ): INodes[] =>
    productNodes.map((node: INodes) => {
      if (node.id === treeNode?.id) {
        return {
          ...treeNode,
          sub_nodes: childData,
        };
      }
      if (node?.sub_nodes) {
        return {
          ...node,
          sub_nodes: _updateTreeData(node?.sub_nodes, treeNode, childData),
        };
      }
      return node;
    });

  const _createBreadcrumbOptions = (selectedNode: INodes) => {
    if (selectedNode?.path && Object.values(selectedNode?.path)?.length) {
      const pathArr = Object.values(selectedNode?.path);
      const parentIdArr = Object.keys(selectedNode?.path);
      const breadCrumbNodes = parentIdArr.map((id: string, index) => ({
        id,
        name: pathArr[index],
      }));
      breadCrumbNodes.push({ id: selectedNode?.id, name: selectedNode?.name });
      dispatch(setBreadCrumbNodes([...breadCrumbNodes]));
    }
    if (selectedNode?.is_root_node)
      dispatch(
        setBreadCrumbNodes([{ id: selectedNode?.id, name: selectedNode?.name }])
      );
  };

  const handleMenuClick = (item: any) => {
    dispatch(setOpenDeleteFolderModalSt(true));
  };

  const _deleteFolder = () => {
    dispatch(setConfirmFolderDelete(false));
    dispatch(
      DELETE("nodes/delete", `${ApiUrl.productList}${selectedRecord?.id}/`)()
    ).then((res: any) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        const updatedTree = _updateTreeData(
          nodes?.sub_nodes || [],
          { ...selectedRecord, isDeleted: true },
          []
        );
        dispatch(setNodes({ ...nodes, sub_nodes: [...updatedTree] }));
        active_node?.id === selectedRecord?.id && _resetNodeSelection();
        triggerToastMessage(
          "Folder Deleted",
          undefined,
          `You deleted '${name}'`
        );

        dispatch(setOpenDeleteFolderModalSt(false));
      }
    });
  };

  const _resetNodeSelection = () => {
    dispatch(setActiveNode(nodes));
    _fetchDocs(type, nodes);
    _createBreadcrumbOptions(nodes);
  };

  return (
    <>
      <li className="d-flex tree">
        <span
          className={`arrow ${isExpanded && "arrow-down"}`}
          onClick={(e) => expandOrCollapseNode(data)}
        >
          {!isLoading ? <RightArrow /> : <TreeLoader />}
        </span>
        <span className="d-flex folder" onClick={() => selectNode(data)}>
          <FolderIcon />
          <span
            className={`name ${
              (active_node?.id === id || customer_active_node?.id === id) &&
              "active"
            }`}
          >
            {capitalizeFirstLetter(name)}
          </span>
          {!data?.is_root_node && !type && isSuperAdmin && (
            <ActionMenu
              data={data}
              items={items}
              handleAction={(item: any) => handleMenuClick(item)}
            />
          )}
        </span>
      </li>
    </>
  );
};
