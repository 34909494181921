import { useState } from "react";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { TextToggleWrapper } from "./TicketsStyled";

export const ticketsTableColumns = [
  {
    title: "ID",
    dataIndex: "ticket_id",
    width: "30%",
  },
  {
    title: "User",
    dataIndex: "name",
    width: "20%",
    render: (_: any, { name }: any) => (
      <>
        <span className="name">{capitalizeFirstLetter(name)}</span>
      </>
    ),
  },
  {
    title: "Message",
    dataIndex: "message",
    width: "35%",
    render: (_: any, { message }: any) => (
      <>
        <MessageToggleView message={message} />
      </>
    ),
  },
  {
    title: "Created Date",
    dataIndex: "created_date",
    width: "15%",
  },
];

export const MessageToggleView = (props: { message: string }) => {
  const [open, setOpen] = useState(false);
  const textTitle =
    props.message?.length > 63 && !open
      ? "Click to expand"
      : props.message?.length > 63 && open
      ? "Click to truncate"
      : "";

  return (
    <TextToggleWrapper
      title={textTitle}
      className={textTitle.length ? "cursor-pointer" : ""}
      onClick={() => setOpen(!open)}
    >
      <div className={open ? "expand" : "truncate"}>{props.message}</div>
    </TextToggleWrapper>
  );
};
