import { MenuProps } from "antd";
import { ColumnsType } from "antd/es/table";
import StatusTag from "../StatusTag/StatusTag";
import { capitalizeFirstLetter } from "../../utils/utils";
import { SortIcon } from "../Icons/Icons";
import { PRODUCT_TYPES, SORT_ORDER } from "../../utils/constants/common";
import { IProductTable } from "../../interface/Product";
import ProductName from "../ProductName/ProductName";
import ProductTableAction from "./ProductTableAction";
import { StatusColumn } from "../../utils/TableStyle";

interface IActionItems {
  products: {
    items: MenuProps["items"];
  };
  customer_products: {
    items: MenuProps["items"];
  };
}

const product_action_items: IActionItems = {
  products: {
    items: [
      {
        key: "product",
        label: "Delete product",
        danger: true,
      },
    ],
  },
  customer_products: {
    items: [
      {
        key: "product",
        label: "Unassign product",
        danger: true,
      },
    ],
  },
};
const platform_action_items: IActionItems = {
  products: {
    items: [
      {
        key: "platform",
        label: "Delete platform",
        danger: true,
      },
    ],
  },
  customer_products: {
    items: [
      {
        key: "platform",
        label: "Unassign platform",
        danger: true,
      },
    ],
  },
};

export const ProductColumns: ColumnsType<IProductTable> = [
  {
    title: "Name",
    dataIndex: "name",
    width: "20%",
    render: (_, { name }) => (
      <>
        <span className="name">{capitalizeFirstLetter(name)}</span>
      </>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    width: "15%",
    render: (_, { type }) => (
      <>
        <StatusTag content={type} />
      </>
    ),
  },
  {
    title: "Customers Assigned",
    dataIndex: "customers",
    width: "30%",
    render: (_, { customers }) => (
      <>
        {customers?.length > 0 ? (
          <ProductName products={customers} />
        ) : (
          <span className="nil">-</span>
        )}
      </>
    ),
  },

  {
    title: "Added Date",
    dataIndex: "added_date",
    width: "20%",
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: true,
    width: "15%",
    className: "status",
    sortIcon: ({ sortOrder }) => {
      return sortOrder === SORT_ORDER.asc ? (
        <SortIcon />
      ) : (
        <span className="rotate-arrow">
          <SortIcon />
        </span>
      );
    },
    render: (_, record) => (
      <StatusColumn>
        <StatusTag content={record?.status} />
        <ProductTableAction
          data={record}
          items={
            record?.type.toLowerCase() === PRODUCT_TYPES.product
              ? product_action_items["products"].items
              : platform_action_items["products"].items
          }
        />
      </StatusColumn>
    ),
  },
];

export const IndividualUserProductColumns: ColumnsType<IProductTable> = [
  {
    title: "Name",
    dataIndex: "name",
    width: "35%",
    render: (_, { name }) => (
      <>
        <span className="name">{capitalizeFirstLetter(name)}</span>
      </>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    width: "30%",
    render: (_, { type }) => (
      <>
        <StatusTag content={type} />
      </>
    ),
  },

  {
    title: "Assigned Date",
    dataIndex: "assigned_date",
    width: "35%",
  },
];

export const CustomerProductColumns: ColumnsType<IProductTable> = [
  {
    title: "Name",
    dataIndex: "name",
    width: "20%",
    render: (_, { name }) => (
      <>
        <span className="name">{capitalizeFirstLetter(name)}</span>
      </>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    width: "15%",
    render: (_, { type }) => (
      <>
        <StatusTag content={type} />
      </>
    ),
  },
  {
    title: "Assigned Date",
    dataIndex: "added_date",
    width: "20%",
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: true,
    width: "15%",
    className: "status",
    sortIcon: ({ sortOrder }) => {
      return sortOrder === SORT_ORDER.asc ? (
        <SortIcon />
      ) : (
        <span className="rotate-arrow">
          <SortIcon />
        </span>
      );
    },
    render: (_, record) => (
      <StatusColumn>
        <StatusTag content={record?.status} />
        <ProductTableAction
          data={record}
          items={
            record?.type?.toLowerCase() === PRODUCT_TYPES.product
              ? product_action_items["customer_products"].items
              : platform_action_items["customer_products"].items
          }
        />
      </StatusColumn>
    ),
  },
];
