import ActionMenu from "../ActionMenu/ActionMenu";
import { MenuProps } from "antd";
import { useAppDispatch } from "../../hooks/reduxHooks";
import {
  setConfirmActionModalType,
  setProductActionModalSt,
} from "../../store/features/Product.slice";
import { isSuperAdminUser } from "../../utils/utils";

const ProductTableAction = ({
  data,
  items,
}: {
  data: any;
  items: MenuProps["items"];
}) => {
  const dispatch = useAppDispatch();
  const isSuperAdmin = isSuperAdminUser();

  const handleMenuClick = (item: any) => {
    dispatch(setConfirmActionModalType(item?.key));
    dispatch(setProductActionModalSt(true));
  };

  return (
    <>
      {isSuperAdmin && (
        <div className="actions">
          <ActionMenu
            data={data}
            items={items}
            handleAction={(item: any) => handleMenuClick(item)}
          />
        </div>
      )}
    </>
  );
};

export default ProductTableAction;
