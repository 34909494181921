import ContentHeader from "../../components/ContentHeader/ContentHeader";
import { PageHeader } from "../../utils/TableStyle";
import DateSelector from "../../components/DateSelector/DateSelector";
import { ActivityLogWrapper } from "./ActivityLogStyled";
import ActivityTimeline, {
  ITimelineObject,
} from "../../components/ActivityTimeline/ActivityTimeline";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { GET } from "../../service/api";
import {
  resetCommonReducer,
  setActivityPageSize,
  setSelectedDate,
} from "../../store/features/CustomerInfo.slice";
import { ApiUrl } from "../../utils/constants/ApiUrl";
import { formatTimelineData } from "../../utils/utils";
import {
  STORAGE_KEYS,
  USER_ACTIVITY_TYPES,
} from "../../utils/constants/common";
import { Empty } from "antd";
import { HeaderWrapper } from "../UserDetail/UserInfoStyled";
import { ContentWrapper } from "../CustomerDetails/CustomerDetailsStyled";
import { updateToggleLoader } from "../../store/features/Common.slice";
import { useLocation } from "react-router-dom";

const ActivityLog = ({ type }: { type?: string }) => {
  const dispatch = useAppDispatch();
  const user = JSON.parse(`${localStorage.getItem(STORAGE_KEYS.user)}`);
  const customer = JSON.parse(
    `${localStorage.getItem(STORAGE_KEYS.currCustomer)}`
  );
  const [userActivityData, setUserActivityData] = useState<ITimelineObject[]>(
    []
  );
  const { activity_log, activityLogParams, reload_list, total_data } =
    useAppSelector((state) => state.CustomerInfoReducer);
  const location = useLocation();

  useEffect(() => {
    dispatch(resetCommonReducer());
    formatActivityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getActivityLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload_list, dispatch]);

  const getActivityLog = () => {
    dispatch(updateToggleLoader(true));
    const customerId =
      type === USER_ACTIVITY_TYPES.customer ? customer.id : user?.customer?.id;
    const url = `${ApiUrl.customerList}${customerId}/${ApiUrl.activityLogs}?page_size=${activityLogParams.page_size}&page=${activityLogParams.page}&date=${activityLogParams.selectedDate}`;
    dispatch(GET("activity-logs/list", url)());
    formatActivityData();
    dispatch(updateToggleLoader(false));
  };

  const formatActivityData = () => {
    const dat: ITimelineObject[] = formatTimelineData(activity_log, type);
    setUserActivityData(dat);
  };

  const updateDate = (dateObj: dayjs.Dayjs, dateString: string) => {
    dispatch(setSelectedDate(dateString));
  };

  const loadMore = ({ target }: any) => {
    const reachedEnd =
      Math.abs(
        target.scrollHeight - (target.scrollTop + target.clientHeight)
      ) <= 10;
    if (
      reachedEnd &&
      (activityLogParams.page_size < total_data ||
        userActivityData.length < total_data)
    ) {
      let page =
        activityLogParams.page_size < total_data
          ? activityLogParams.page_size + 6
          : activityLogParams.page_size;
      dispatch(setActivityPageSize(page));
      getActivityLog();
    }
  };

  return (
    <>
      <PageHeader>
        {type === USER_ACTIVITY_TYPES.customer ? (
          <HeaderWrapper>
            <p className="user-info-header">
              <span className="title">Activity Log</span>
              <span className="sub">Activities under this customer</span>
            </p>
          </HeaderWrapper>
        ) : (
          <ContentHeader />
        )}
        <div>
          <DateSelector
            date={activityLogParams.selectedDate}
            dateChange={(dateObj: dayjs.Dayjs, dateString: string) =>
              updateDate(dateObj, dateString)
            }
          />
        </div>
      </PageHeader>
      {activity_log.length > 0 && userActivityData[0]?.label && (
        <>
          <ActivityLogWrapper
            height={type === USER_ACTIVITY_TYPES.customer ? "33rem" : "73rem"}
            onScroll={(ev: any) => loadMore(ev)}
          >
            <ActivityTimeline data={userActivityData} />
          </ActivityLogWrapper>
        </>
      )}
      {activity_log?.length === 0 && (
        <ContentWrapper className="empty-table">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </ContentWrapper>
      )}
    </>
  );
};

export default ActivityLog;
