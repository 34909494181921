import { createBrowserRouter, Navigate } from "react-router-dom";
import React, { Suspense } from "react";
import Login from "../pages/Login/Login";
import Loader from "../components/Loader/Loader";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import PrivateRoute from "../utils/PrivateRoute";
import Error from "../components/Error/Error";
import { hasNoProducts, isIndividualUser } from "../utils/utils";
import LinkExpiry from "../components/LinkExpired/LinkExpiry";
import { USER_ROLES } from "../utils/constants/common";

const SigninLayout = React.lazy(() => import("../layout/SigninLayout"));
const LandingLayout = React.lazy(() => import("../layout/DashboardLayout"));
const Customer = React.lazy(() => import("../pages/Customer/Customer"));
const Products = React.lazy(() => import("../pages/Products/Products"));
const Users = React.lazy(() => import("../pages/User/User"));
const Settings = React.lazy(() => import("../pages/Settings/Settings"));
const Signup = React.lazy(() => import("../pages/Signup/Signup"));
const BlockedIPs = React.lazy(() => import("../pages/BlockedIPs/BlockedIPs"));
const Uploads = React.lazy(() => import("../pages/Uploads/Uploads"));
const ContactUs = React.lazy(() => import("../pages/ContactUs/ContactUs"));
const CustomerDetails = React.lazy(
  () => import("../pages/CustomerDetails/CustomerDetails")
);
const ProductDetail = React.lazy(
  () => import("../pages/ProductDetail/ProductDetail")
);
const UserDetail = React.lazy(() => import("../pages/UserDetail/UserDetail"));

const IndexPage = () => {
  const indexpage =
    hasNoProducts() || isIndividualUser() ? "products" : "customers";
  return <Navigate to={indexpage} />;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <SigninLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
    ],
    errorElement: <Error />,
  },
  {
    path: "activate/user",
    element: (
      <Suspense fallback={<Loader />}>
        <SigninLayout />
      </Suspense>
    ),
  },
  {
    path: "/home",
    element: (
      <Suspense fallback={<Loader />}>
        <PrivateRoute>
          <LandingLayout />
        </PrivateRoute>
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <IndexPage />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "products",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <Products />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "products/detail/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <ProductDetail />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "users",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={[USER_ROLES.SUPER_ADMIN]}>
              <Users />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "users/detail/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute roles={[USER_ROLES.SUPER_ADMIN]}>
              <UserDetail />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "settings",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "customers",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <Customer />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "customers/detail/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <CustomerDetails />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "blocked_ips",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <BlockedIPs />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      // {
      //   path: "activity_log",
      //   element: (
      //     <Suspense fallback={<Loader />}>
      //       <PrivateRoute>
      //         <ActivityLog type={USER_ACTIVITY_TYPES.individual} />
      //       </PrivateRoute>
      //     </Suspense>
      //   ),
      //   errorElement: <Error />,
      // },
      {
        path: "contact_us",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <ContactUs />
            </PrivateRoute>
          </Suspense>
        ),
        errorElement: <Error />,
      },
      {
        path: "my_files",
        element: (
          <Suspense fallback={<Loader />}>
            <PrivateRoute>
              <Uploads />
            </PrivateRoute>
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "link-expired",
    element: <LinkExpiry />,
  },
  {
    path: "access-denied",
    element: <PageNotFound noAccess={true} />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);
