export const VERSION = "v1/";

export const ApiUrl = {
  login: "auth/login/",
  otp: "users/totp/login/",
  createPassword: "users/set-password/",
  generateQRCode: "users/totp/create/",
  downloadCodes: "users/static/file/",
  getRecoveryTokens: "users/static/create/",
  validateToken: "users/validate-token/",
  logout: "auth/logout/",
  customerList: "customers/",
  recoveryCodeLogin: "users/static/login/",
  productList: "nodes/pragmatic/",
  userList: "users/",
  roles: "roles/",
  addCustomerUser: "users/roles/",
  assignPragmaticUser: "users/roles/",
  customerProductList: "customers/{id}/nodes/",
  customerSpecificNodes: "nodes/customer-specific/",
  assignProducts: "customers/assign/",
  uploadDocument: "nodes/upload-file/",
  downloadDocument: "nodes/download-file/",
  tickets: "support-tickets/",
  blockedIps: "auth/blocked-ips/",
  unblockIP: "auth/unblock-ip/",
  supportTickets: "support-tickets/",
  activityLogs: "activity-logs/",
};
