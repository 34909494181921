export const VALIDATION_ERRORS = {
  email: "Email is required",
  password: "Password is required",
  validEmail: "Please enter a valid email",
  validPassword: "Please enter a valid password",
  name: "Name is required",
  recoveryCode: "Recovery code is required",
  validCode: "Please enter a valid  code",
  selectUser: "Please select a user",
  selectRole: "Please select a role",
  selectAtleastOneProduct: "Please select atleast one product/platform",
};

export const USER_TYPES = {
  admin: "Admin",
  viewer: "Viewer",
  superadmin: "Superadmin",
  superuser: "Customer superuser",
  individual: "Customer individual",
};

export enum REGISTRATION_STEPS {
  MFA = "mfa",
  SIGNUP_SUCCESS = "signup-success",
  CREATE_PASSWORD = "create-password",
}
export enum HTTP_METHODS {
  GET = "get",
  POST = "post",
}

export enum STATUS {
  SUCCESS = "success",
  ERROR = "error",
}

export enum USER_STATUS {
  NV = "NV",
  AV = "AV",
}

export enum USER_ROLES {
  SUPER_ADMIN = "super admin",
  VIEWER = "viewer",
  INDIVIDUAL = "individual",
}

export const APP_IDLE_TIME = 30;

export enum SORT_ORDER {
  asc = "ascend",
  desc = "descend",
}

export enum ERROR_CODES {
  UNAUTHORIZED = 401,
}

export enum UPLOAD_STATUSES {
  notStarted = "notStarted",
  inProgress = "inProgress",
  success = "success",
  error = "error",
}

export const RESTRICTED_FOLDERS = ["documentations", "designs"];

export enum STORAGE_KEYS {
  currCustomer = "currentCustomer",
  user = "user",
}

export const FILE_UPLOAD = {
  MAX_SIZE_MB: 200,

  FILE_TYPES: [
    ".docx",
    ".zip",
    ".pdf",
    ".tar.gz",
    ".tar",
    ".txt",
    ".ppt",
    ".csv",
    ".md5",
    ".gds",
    ".oas",
  ],
};

export enum PRODUCT_TYPES {
  product = "product",
  platform = "platform",
}

export enum USER_ACTIVITY_TYPES {
  customer = "customer",
  individual = "individual",
}

export enum CUSTOMER_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
export const FILE_UPLOAD_CATEGORIES = {
  documentations: "Documentations",
  designs: "Designs",
};
