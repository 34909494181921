import { Form, Input, Modal, Select } from "antd";
import { useState } from "react";
import { STATUS, VALIDATION_ERRORS } from "../../utils/constants/common";
import { emailValidator } from "../../utils/validators";
import { FormLineItemWrapper } from "../../pages/UserDetail/UserInfoStyled";
import DynamicColorSelectWrapper from "./DynamicColorSelectWrapper";
import { userStatusOptions } from "../../pages/UserDetail/UserDetailsData";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { GET, PATCH, PUT } from "../../service/api";
import { ApiUrl } from "../../utils/constants/ApiUrl";
import { triggerToastMessage } from "../../utils/utils";
import { IUserDetails } from "../../interface/UserDetail";
import { RESPONSE_MESSAGES } from "../../utils/constants/messages";

const EditUserInfoModal = ({
  isOpen,
  closeModal,
  type = "User",
  data,
}: {
  isOpen: boolean;
  closeModal: Function;
  type?: string;
  data: IUserDetails;
}) => {
  const DEFAULT_SELECTED: string = "Active";

  const dispatch = useAppDispatch();

  const [editUserForm] = Form.useForm();
  const [btnStatus, setBtnStatus] = useState(true);
  const [selectedValue, setSelectedValue] = useState(
    data.status || DEFAULT_SELECTED
  );

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
  };

  const handleResponse = (payload: any) => {
    let subMessage = "";
    if (payload.name !== data.name && payload.status !== data.status) {
      subMessage = RESPONSE_MESSAGES.EDIT_USER.both_changed;
    } else {
      if (payload.name !== data.name)
        subMessage = RESPONSE_MESSAGES.EDIT_USER.name_changed;
      if (payload.status !== data.status)
        subMessage = RESPONSE_MESSAGES.EDIT_USER.status_changed;
    }
    triggerToastMessage(
      RESPONSE_MESSAGES.EDIT_USER.message,
      STATUS.SUCCESS,
      subMessage
    );
  };

  const editUser = () => {
    setBtnStatus(true);
    let payload = editUserForm.getFieldsValue();
    payload.status = selectedValue;
    switch (type) {
      case "User":
        updateUser(payload);
        break;
      case "Customer":
        updateCustomer(payload);
        break;
      default:
        break;
    }
  };

  const updateUser = (payload: any) => {
    dispatch(
      PATCH("users/edit", `${ApiUrl.userList}${data.id}/`, payload)()
    ).then((res) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        handleResponse(payload);
        dispatch(GET("users/detail", `${ApiUrl.userList}${data.id}/`)());
        reset();
      }
    });
  };

  const updateCustomer = (payload: any) => {
    dispatch(
      PUT("customer/update", `${ApiUrl.customerList}${data.id}/`, payload)()
    ).then((res) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        handleResponse(payload);
        dispatch(GET("customer/detail", `${ApiUrl.customerList}${data.id}/`)());
        reset();
      }
    });
  };

  const reset = () => {
    closeModal();
    editUserForm.resetFields();
    setBtnStatus(true);
  };

  const handleFormChange = () => {
    const hasErrors = editUserForm
      .getFieldsError()
      .some(({ errors }) => errors.length);
    setBtnStatus(hasErrors);
  };

  const triggerValidationOnBlur = (field: string) => {
    const val = editUserForm.getFieldValue(field);
    editUserForm.setFieldsValue({ [field]: val?.trim() });
    editUserForm.validateFields([field]);
  };

  return (
    <>
      <Modal
        destroyOnClose
        className="modal-title"
        title={`Edit ${type} Info`}
        open={isOpen}
        okText="Update"
        okButtonProps={{ size: "large", disabled: btnStatus }}
        cancelButtonProps={{ size: "large", type: "text" }}
        onOk={editUser}
        onCancel={reset}
        closable={false}
      >
        <Form
          onFieldsChange={handleFormChange}
          autoComplete="off"
          size="large"
          form={editUserForm}
          layout="vertical"
          name="editUserForm"
          requiredMark={false}
          initialValues={{
            name: data.name,
            email: data.email,
            status: data.status,
          }}
        >
          <FormLineItemWrapper>
            <Form.Item
              style={{ flexGrow: 1 }}
              name="name"
              label={type === "User" ? "Name" : `${type} Name`}
              rules={[
                {
                  required: true,
                  message: VALIDATION_ERRORS.name,
                  validateTrigger: "onBlur",
                },
                {
                  min: 3,
                  message: `${type} name should be atleast 3 characters`,
                  validateTrigger: "onBlur",
                },
              ]}
            >
              <Input
                autoComplete="edit-name"
                defaultValue={data.name}
                placeholder={type === "User" ? "Name" : `${type} Name`}
                onBlur={() => triggerValidationOnBlur("name")}
                minLength={3}
              />
            </Form.Item>
            <DynamicColorSelectWrapper type={selectedValue}>
              <Form.Item name="status" label="Status">
                <Select
                  className="dynamic-color-select"
                  value={selectedValue}
                  onChange={handleSelectChange}
                  defaultValue={DEFAULT_SELECTED}
                  style={{ width: "12rem", height: "4.8rem" }}
                  options={userStatusOptions}
                />
              </Form.Item>
            </DynamicColorSelectWrapper>
          </FormLineItemWrapper>
          {type === "User" && (
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: VALIDATION_ERRORS.email,
                  validateTrigger: "onBlur",
                },
                {
                  pattern: emailValidator,
                  message: VALIDATION_ERRORS.validEmail,
                  validateTrigger: "onBlur",
                },
              ]}
            >
              <Input
                disabled
                autoComplete="new-email"
                placeholder="User Email"
                defaultValue={data.email}
                onBlur={() => triggerValidationOnBlur("email")}
                minLength={3}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default EditUserInfoModal;
