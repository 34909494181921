import styled from "styled-components";

export const TreeWrapper = styled.ul`
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 0;
  .arrow {
    width: 2rem;
    height: 2rem;
    display: flex;
    place-content: center;
    align-items: center;
    &: hover {
      background: var(--BORDER);
      border-radius: 4px;
    }
  }
  .folder {
    align-items: center;
    padding: 0.6rem 0.8rem;
    border-radius: 0.6px;
    gap: 0.7rem;
  }
  .tree {
    cursor: pointer;
    gap: 0.6rem;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 300;
    .name,
    .arrow,
    svg {
      flex-shrink: 0;
    }
    .active {
      padding: 0.2rem 0.8rem;
      background: var(--PRIMARY);
      color: var(--WHITE);
      border-radius: 4px;
    }
    .arrow-down {
      transform: rotate(90deg);
    }
    &:hover {
      .folder {
        background: #f1f7ff;
      }
      .more {
        display: block;
      }
    }
  }
  .sub-nodes {
    margin-left: 1.5rem;
  }
  .more {
    display: none;
    margin-left: 3rem;
  }
`;
