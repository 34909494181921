export const RESPONSE_MESSAGES = {
  LOGIN_SUCCESS: "Successfully Logged in",
  ADD_CUSTOMER: {
    message: "Customer Added",
    description: "You added a customer",
  },
  ADD_USER: {
    message: "User Added",
    description: "We sent an invitation to this user",
  },
  ADD_CUSTOMER_USER: {
    message: "User Added",
    description: "We sent an invitation to this user",
  },
  ADD_PRODUCT: {
    message: " Added",
    description: "You added a ",
  },
  ADD_FOLDER: {
    message: "Folder Added",
    description: "You added a folder ",
  },
  IP_UNBLOCKED: {
    message: "IP Address Unblocked",
  },
  ASSIGN_USER: {
    message: "User Assigned",
    description: "You assigned a user to this customer",
  },
  ASSIGN_PRODUCT: {
    message: "Product Assigned",
    description: "You assigned a product",
  },
  ASSIGN_PLATFORM: {
    message: "Platform Assigned",
    description: "You assigned a platform",
  },
  EDIT_USER: {
    message: "User Info Updated",
    name_changed: "Name has been updated",
    status_changed: "Status has been updated",
    both_changed: "Name and status have been updated",
  },
  SUPPORT_TICKET: {
    message: "Thank you for reaching out!",
    sub: "Our team will review it shortly.",
  },
  DELTE_PRODUCT: {
    message: " Deleted",
    description: "You deleted the ",
  },
  UNASSIGN_PRODUCT: {
    message: " Unassigned",
    description: "You unassigned the ",
  },
};

export const CONFIRM_MODAL_MESSAGES: {
  [key: string]: { title: string; description: string };
} = {
  PRODUCTS_AND_PLATFORMS: {
    title: "Are you sure you want to delete this ",
    description:
      "This will affect all assigned customers and cannot be undone.",
  },
  CUSTOMER_PRODUCTS: {
    title: "Are you sure you want to unassign this ",
    description:
      "This product will no longer be shared with the customer, but you can reassign it at any time.",
  },
  DELETE_USER: {
    title: "Are you sure you want to delete this user?",
    description:
      "All data associated with this user will be permanently removed from the system. This action cannot be undone.",
  },
  DELETE_CUSTOMER: {
    title: "Are you sure you want to delete this customer?",
    description:
      "All data associated with this customer users, and assigned products will be permanently removed from the system.",
  },
};
