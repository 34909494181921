import { useEffect, useState } from "react";
import { TableProps } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { DELETE, GET } from "../../service/api";
import useDebounce from "../../hooks/useDebounce";
import {
  reloadTable,
  setProductActionModalSt,
  setProductTableLoader,
  setSearchParam,
  updateProductTableParam,
} from "../../store/features/Product.slice";
import { IProductTable } from "../../interface/Product";
import { setCurrentProduct } from "../../store/features/ProductDetails.slice";
import ProductListTable from "./ProductListTable/ProductListTable";
import { PRODUCT_TABLE_TYPES, ProductTableTypes } from "./productTableTypes";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import {
  CONFIRM_MODAL_MESSAGES,
  RESPONSE_MESSAGES,
} from "../../utils/constants/messages";
import { ApiUrl } from "../../utils/constants/ApiUrl";
import { updateToggleLoader } from "../../store/features/Common.slice";
import { STATUS } from "../../utils/constants/common";
import { isIndividualUser, triggerToastMessage } from "../../utils/utils";
import { IndividualUserProductColumns } from "./ProductTableColumnData";

const ProductList = ({
  type,
  endPoint,
}: {
  type: keyof ProductTableTypes;
  endPoint?: string;
}) => {
  const dispatch = useAppDispatch();
  const { selectedRecord } = useAppSelector(
    (state) => state.CustomerDetailsReducer
  );
  const {
    product_list,
    customer_product_list,
    product_table_loader,
    productTableParam,
    total_data,
    reload_table,
    openActionModal,
    confirm_modal_type,
  } = useAppSelector((state) => state.ProductReducer);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 1200);
  const isCustomer = isIndividualUser();

  const getEndPoint = (tableType: keyof ProductTableTypes): string => {
    return PRODUCT_TABLE_TYPES[tableType].endPoint;
  };

  const navigate = useNavigate();

  const sourceURL = endPoint || getEndPoint(type);

  const getProductsList = () => {
    let url = `${sourceURL}?page_size=10&page=${productTableParam.page}`;
    if (debouncedSearchTerm) url = url + `&search=${debouncedSearchTerm}`;
    if (productTableParam.ordering !== "")
      url = `${url}${productTableParam.ordering}`;
    dispatch(GET(PRODUCT_TABLE_TYPES[type].action, url)());
  };

  //useEffetct hook that will run based on changing search, page and sort params to fetch table data
  useEffect(() => {
    getProductsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productTableParam.ordering, debouncedSearchTerm]);

  useEffect(() => {
    if (reload_table) getProductsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload_table]);

  //Function that executes on changing the search input and setting searched value in slice
  const searchData = (searchParam: string) => {
    if (!searchParam) dispatch(setProductTableLoader(true));
    setSearchTerm(searchParam);
    dispatch(setSearchParam(searchParam));
    if (searchParam)
      dispatch(updateProductTableParam({ ...productTableParam, page: 1 }));
  };

  //function on changing table props -> sort
  const changeTableProps: TableProps<IProductTable>["onChange"] = (
    _,
    __,
    sorter: any,
    extra
  ) => {
    switch (extra.action) {
      case "sort":
        let ordering = "";
        if (sorter.order === "descend" || sorter.order === "ascend") {
          ordering = `&ordering=${
            sorter.order === "descend"
              ? "-status"
              : sorter.order === "ascend"
              ? "status"
              : ""
          }`;
        }
        dispatch(
          updateProductTableParam({
            ...productTableParam,
            ordering,
          })
        );
        break;
      default:
        break;
    }
  };

  //function on changing page
  const changePage = (page: number) => {
    dispatch(updateProductTableParam({ ...productTableParam, page }));
    dispatch(reloadTable(true));
  };

  const onRowClick = (record: any) => {
    dispatch(setCurrentProduct(record));
    navigate(`/home/products/detail/${record.id}`);
  };

  const confirmAction = () => {
    dispatch(updateToggleLoader(true));
    const actionType =
      type === "CUSTOMER_PRODUCTS"
        ? "customer-products/unassign"
        : "nodes/delete";
    const url =
      type === "CUSTOMER_PRODUCTS"
        ? `${ApiUrl.assignProducts}${selectedRecord?.assignment_id}/`
        : `${ApiUrl.productList}${selectedRecord?.id}/`;
    dispatch(DELETE(actionType, url)()).then((res: any) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        dispatch(reloadTable(true));
        dispatch(setProductActionModalSt(false));
        _showToastMessage();
      }
      dispatch(updateToggleLoader(false));
    });
  };

  const _showToastMessage = () => {
    const message = `${selectedRecord?.type}${
      type === "CUSTOMER_PRODUCTS"
        ? RESPONSE_MESSAGES.UNASSIGN_PRODUCT.message
        : RESPONSE_MESSAGES.DELTE_PRODUCT.message
    }`;
    const desc = `${
      type === "CUSTOMER_PRODUCTS"
        ? RESPONSE_MESSAGES.UNASSIGN_PRODUCT.description
        : RESPONSE_MESSAGES.DELTE_PRODUCT.description
    }${selectedRecord?.type} ${selectedRecord?.name}`;

    triggerToastMessage(message, undefined, desc);
  };
  return (
    <>
      <ProductListTable
        changeTableProps={changeTableProps}
        columns={
          !isCustomer
            ? PRODUCT_TABLE_TYPES[type].columns
            : IndividualUserProductColumns
        }
        type={type}
        list={
          type === "CUSTOMER_PRODUCTS" ? customer_product_list : product_list
        }
        onPageChange={changePage}
        onRowClick={onRowClick}
        options={PRODUCT_TABLE_TYPES[type]}
        onSearchChange={searchData}
        states={{
          currentPage: productTableParam.page,
          table_loader: product_table_loader,
          total_data: total_data,
          search_param: searchTerm,
        }}
      />
      <ConfirmationModal
        customClass="error-btn"
        open={openActionModal}
        okText={type === "CUSTOMER_PRODUCTS" ? "Unassign" : "Delete"}
        title={`${CONFIRM_MODAL_MESSAGES[type].title}${confirm_modal_type}?`}
        subText={`${CONFIRM_MODAL_MESSAGES[type].description}`}
        onCancel={() => dispatch(setProductActionModalSt(false))}
        onOk={confirmAction}
      />
    </>
  );
};

export default ProductList;
